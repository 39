<template>
    <div>
        <!-- 添加模块组件弹窗 -->
        <el-dialog title="添加模块" :visible.sync="dialogFormVisible">
            <el-form :model="moduleData">
                <el-form-item label="模块code" :label-width="'120px'">
                    <el-input
                        v-model="moduleData.module_code "
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="模块名称" :label-width="'120px'">
                    <el-input
                        v-model="moduleData.module_name"
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="createProductModule()"
                    >确认</el-button
                >
            </div>
        </el-dialog>

        <div class="commonInputTopBox">
            <infoHeader :content_name="'软件系统异常故障配置： ' + infoData.product_name" />
        </div>

        <!-- 产品信息 -->
        <el-descriptions
            title="产品信息"
            direction="vertical"
            :column="3"
            border
        >
            <el-descriptions-item label="产品系统ID">{{
                infoData.id
            }}</el-descriptions-item>
            <el-descriptions-item label="产品名">{{
                infoData.product_name
            }}</el-descriptions-item>
            <el-descriptions-item label="产品类型">{{
                infoData.product_type
            }}</el-descriptions-item>
        </el-descriptions>
        
        <!-- 切换选项卡 -->
        <el-tabs v-model="activeName" type="card" style="margin-top: 30px" @tab-click="handleTabClick(activeName)">

            <el-descriptions title="异常处理模块配置">
                    <template slot="extra">
                        <el-button
                            type="primary"
                            size="small"
                            @click="dialogFormVisible = true"
                            >添加模块</el-button
                        >
                    </template>
                </el-descriptions>

                <template>
                    <el-table
                        :data="mouduleTableData"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="module_code"
                            label="模块ID"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="module_name"
                            label="模块名称"
                        >
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="300">
                            <template slot-scope="scope">
                                <el-button
                                type="success"
                                    @click="
                                        toModuleInfo(
                                            scope.row
                                        )
                                    "
                                    >详情</el-button
                                >
                                <el-button
                                type="primary"
                                    @click="
                                        handleEditModle(
                                            scope.row
                                        )
                                    "
                                    >编辑</el-button
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                </template>


        </el-tabs>
    </div>
</template>

<script>
import {
    product_info,
} from '@/api/manage/operation/product/product.js';

import {
    fault_module_query, fault_module_create,fault_module_update
} from '@/api/manage/operation/software_fault_managent/fault_module.js';
// 载入组件

export default {
    name: 'productLibraryInfo',
    data() {
        return {
            infoData: undefined,
            activeName: '1',
            cacheData: {
            },
            dialogFormVisible: false,
            popovData: {
            },
            tableData: {
                product_material_list: [],
                equipment_library_list: [],
            },
            mouduleTableData: [],
            moduleData: {
                product_id:undefined
            }
        };
    },
    components: {
    },
    created() {
        this.infoData = this.$route.query;
        let {id} = this.infoData;
        this.moduleData.product_id = id;
        this.getProductInfo(id);
        this.getProductModule()
    },
    methods: {
        handleEditModle(item) {
            // handle edit logic here
            this.$prompt('重命名的模块名', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then(({ value }) => {
                    console.log(item.id);
                    fault_module_update({
                        id: item.id,
                        module_name: value
                    }).then(() => {
                        this.$router.go(0);
                    });
                })
                .catch(() => {
                    this.$message({ type: 'info', message: '取消输入' });
                });
        },
        getProductInfo(ids){
            product_info({id:ids}).then((res)=>{
                console.log(res);
            })
        },
        toModuleInfo(item) {
            console.log('item', item);
            item['product_name'] = this.infoData.product_name
            this.$router.push({name:'software_Fault_Info_modulemanager',query:item})
        },
        handleEdit(item) {
            item.id;
        },
        handleTabClick(sortIndex){
            if(sortIndex == 1) this.getProductModule(); 
        },
        getProductModule(){
            fault_module_query(this.moduleData).then((res)=>{
                this.mouduleTableData = res.data;
            }).catch((error)=>{
                this.$message.error(error.msg)
            })
        },
        createProductModule(){
            fault_module_create(this.moduleData).then((res)=>{
                if(res.code == 200){
                    this.$message.success(res.msg)
                    this.$router.go(0)
                }else{
                    this.$message.error(res.msg)
                }
            }).catch((error)=>{
                console.log(error);
            })
            this.dialogFormVisible = false
        }
    }
};
</script>

<style lang="scss" scoped></style>
