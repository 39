import request from '@/utils/request'


export function fault_module_query(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/fault_module/query',
        method: 'post',
        data: data
    })
}


export function fault_module_create(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/fault_module/create',
        method: 'post',
        data: data
    })
}


export function fault_module_delete(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/fault_module/del',
        method: 'post',
        data: data
    })
}
export function fault_module_update(data) {
    return request({
        url: '/platform_api/manage_api/software_fault_managent/fault_module/update',
        method: 'post',
        data: data
    })
}
